import React from "react";
import scrollIcon from "../assets/scroll-down.png";
import ImageUrls from "../components/ImageUrls";
import "../static/fonts/font.css";
import { useMediaQuery } from "react-responsive";
import ruienm_logo from "../assets/ruienm_logo.png";

const MainPage = ({ id }) => {
  const isMobile = useMediaQuery({ maxWidth: 480 });
  const isTablet = useMediaQuery({ minWidth: 481, maxWidth: 1024 });
  const isBrowser = useMediaQuery({ minWidth: 1025 });

  const sectionStyle = {
    width: "100.0000vw",
    height: isTablet ? "66vh" : "100.0000vh",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    backgroundSize: "cover", // Use "cover" for scaling
    backgroundPosition: isMobile
      ? "center center"
      : isTablet
      ? "center center"
      : "center center", // Adjust this line
    position: "relative",
  };

  const overlayStyle = {
    position: "absolute",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    backgroundColor: "rgba(0, 0, 0, 0.5)", // Adjust the opacity here
  };

  const text1Style = {
    position: "absolute",
    width: isMobile ? "80.0000vw" : isTablet ? "" : "",
    height: isMobile ? "1.3021vw" : isTablet ? "" : "",
    left: isMobile ? "10vw" : isTablet ? "" : "38vw",
    top: isMobile ? "32vh" : isTablet ? "25vh" : "36.7593vh",
    opacity: "1.00",
    fontFamily: "Pretendard Variable",
    fontSize: isMobile ? "3.1250vw" : isTablet ? "1.5vw" : "1.0471vw",
    fontWeight: "500",
    fontStyle: "normal",
    textAlign: isMobile ? "center" : "center",
    lineHeight: isMobile ? "5vw" : "4.8438vw",
    color: "#F2F3F5",
  };

  const text2Style = {
    position: "absolute",
    width: isMobile ? "90.0000vw" : isTablet ? "" : "",
    height: isMobile ? "8.6979vw" : isTablet ? "" : "",
    left: isMobile ? "5vw" : isTablet ? "28vw" : "30vw",
    top: isMobile ? "35vh" : isTablet ? "28vh" : "39.9074vh",
    opacity: "1.00",
    fontFamily: "SCoreDream",
    fontSize: isMobile ? "7.3750vw" : isTablet ? "4vw" : "3.1250vw",
    fontWeight: isMobile ? "900" : "700",
    fontStyle: "normal",
    textAlign: isMobile ? "center" : "center",
    lineHeight: isMobile ? "11vw" : "4.1667vw",
    color: "#F2F3F5",
  };

  const logoStyle = {
    position: "absolute",
    // Adjust the following properties according to your layout
    width: isMobile ? "30vw" : isTablet ? "15vw" : "10vw",
    height: "auto", // Adjust as needed
    top: isMobile ? "75vh" : isTablet ? "49.5vh" : "75vh", // Adjust the vertical position
    left: isMobile ? "35vw" :isTablet ? "43vw" : "43vw", // Adjust the horizontal position
  };

  const text3Style = {
    position: "absolute",
    width: isMobile ? "" : "8.6875vw",
    height: isMobile ? "1.0417vw" : "2vh",
    left: isMobile ? "3.5vw" : "1vw",
    top: isMobile ? "7vh" : "-0.4vh",
    opacity: "1.00",
    fontFamily: "SCoreDream",
    fontSize: isMobile ? "3.1250vw" : "0.8333vw",
    fontWeight: "500",
    fontStyle: "normal",
    textAlign: isMobile ? "center" : "center",
    color: "#F2F3F5",
  };

  const pageGroupStyle = {
    position: "absolute",
    width: isMobile ? "18vw" : "5.7292vw",
    height: isMobile ? "18vw" : "5.7292vw",
    left: isMobile ? "80vw" : "92.1875vw",
    top: isMobile ? "90vh" : isTablet ? "60vh" : "85vh",
    backgroundColor: "transparent",
  };

  const page1Style = {
    position: "absolute",
    width: isMobile ? "3.5417vw" : "3.5417vw",
    height: isMobile ? "3.5938vw" : "6.2963vh",
    left: isMobile ? "0vw" : "0.0000vw",
    top: isMobile ? "-3.5vh" : "-4.1667vw",
    opacity: "1.00",
    fontFamily: "Pretendard Variable",
    fontSize: isMobile ? "9.3750vw" : "3.1250vw",
    fontWeight: isMobile ? "900" : "500",
    fontStyle: "normal",
    textAlign: "center",
    lineHeight: "4.8438vw",
    color: "#F2F3F5",
  };

  const page2Style = {
    position: "absolute",
    width: isMobile ? "2.3958vw" : "3.5417vw",
    height: isMobile ? "2.4479vw" : "6.2963vh",
    left: isMobile ? "9vw" : "2.4vw",
    top: isMobile ? "3vh" : "0vh",
    opacity: "0.20",
    fontFamily: "Pretendard Variable",
    fontSize: isMobile ? "6.2500vw" : "2.0833vw",
    fontWeight: isMobile ? "900" : "500",
    fontStyle: "normal",
    textAlign: "center",
    lineHeight: "4.8438vw",
    color: "#F2F3F5",
  };

  const dividerStyle = {
    position: "absolute",
    width: isMobile ? "10vw" : "2.6042vw",
    height: isMobile ? "0.1042vw" : "0.1042vw",
    left: isMobile ? "5vw" : "2vw",
    top: isMobile ? "10vw" : "3vw",
    opacity: "1.00",
    backgroundColor: "#F2F3F5",
    transform: "rotate(-45deg)",
  };

  const circleStyle = {
    position: isMobile ? "relative" : isTablet ? "absolute" : "absolute",
    width: isMobile ? "120vw" : isTablet ? "50vw" : "31.2500vw",
    height: isMobile ? "120vw" : isTablet ? "50vw" : "55.5556vh",
    left: isMobile ? "0vw" : isTablet ? "42vw" : "44.5313vw",
    top: isMobile ? "0vh" : isTablet ? "15vh" : "22.2222vh",
    backgroundColor: "#FFFFFF",
    borderRadius: "50%",
  };

  const scrollIconStyle = {
    position: "absolute",
    height: isMobile ? "10vw" : "2vw",
    left: isMobile ? "7vw" : "0vw",
    top: isMobile ? "0vh" : "0vh",
    opacity: "1.00",
  };

  const scrollIconGroupStyle = {
    position: "absolute",
    width: isMobile ? "25vw" : "8.7292vw",
    height: isMobile ? "25vw" : "2vw",
    left: isMobile ? "" : "44vw",
    top: isMobile ? "60vh" : isTablet ? "39vh" : "64vh",
    backgroundColor: "transparent",
  };

  return (
    <section
      id={id}
      style={{
        ...sectionStyle,
        backgroundImage: `url(${ImageUrls[21]})`,
        // backgroundSize: "100% 100%",
      }}
    >
      <div style={overlayStyle}></div>
      <p style={text1Style}>Lead the future with 20 years + know-how</p>

      <div>
        <p style={text2Style}>
          Entertain Performance <br /> for Next Generation
        </p>
      </div>
      <img src={ruienm_logo} alt="Rui ENM Logo" style={logoStyle} />
      <div style={pageGroupStyle}>
        <div>
          <p style={page1Style}>01</p>
        </div>
        <div>
          <p style={page2Style}>06</p>
        </div>
        <div style={dividerStyle}></div>
      </div>
    </section>
  );
};

export default MainPage;
